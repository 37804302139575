<template>
  <img
    :src="src"
    alt="Image"
    class="img-fluid"
    :class="{
      click: to,
    }"
    @click="goTo(to)"
  />
</template>

<script>
export default {
  props: ["src", "to"],
  methods: {
    goTo(to) {
      if (to) {
        if (to.indexOf("http") > -1) window.open(to, "_blank");
        else this.$router.push(this.getLink(to));
      }
    },
  },
};
</script>